<template>
  <div class="product_detail">
    <div class="title">
      <h2>
        Product Detail:
        <span>{{ packageName }}</span>
      </h2>
    </div>
    <div>
      <el-card>
        <el-tabs v-model="activeName" type="border-card">
          <!-- 开关针对包名拉黑所有渠道，不让所有渠道跑该包名的offer -->
          <el-tab-pane label="Black For All Affiliate" name="first">
            <div style="margin:20px;">
              <span style="margin-left:20px;font-size:20px;">Black For All Affiliate</span>
              <el-switch
                v-model="status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="true"
                inactive-value="false"
                @change="statusChange"
              ></el-switch>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Add Adjust Prod Rate" name="second">
            <add-adjust-prod-rate :packageName="packageName" />
          </el-tab-pane>
          <!--Setting-->
          <el-tab-pane label="Black For Source" name="third">
            <black-for-source :packageName="packageName" />
          </el-tab-pane>
          <el-tab-pane label="Black For Affiliate" name="fourth">
            <black-for-affiliate :packageName="packageName" :prodId="prodId" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { switchProdAffBlack } from 'api/product/detail';
  import AddAdjustProdRate from './components/AddAdjustProdRate.vue';
  import BlackForSource from './components/BlackForSource.vue';
  import BlackForAffiliate from './components/BlackForAffiliate.vue';
  export default {
    components: {
      AddAdjustProdRate,
      BlackForSource,
      BlackForAffiliate,
    },
    data() {
      return {
        activeName: 'first',
        prodId: this.$route.query.prodId,
        packageName: this.$route.query.packageName,
        status: this.$route.query.affBlacklistSwitch + '',
      };
    },
    methods: {
      statusChange(val) {
        switchProdAffBlack(this.prodId, val).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.status = val;
          } else {
            this.status = !val;
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
    },
  };
</script>
