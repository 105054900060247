<template>
  <div>
    <el-form :inline="true" :model="createRet" label-width="80px">
      <el-form-item label="Rate">
        <el-input v-model="createRet.rate"></el-input>
      </el-form-item>
      <el-form-item label="Description">
        <el-input v-model="createRet.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createRetClick">Create</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="retList" border stripe style="width:100%;">
      <el-table-column label="Product" align="center" min-width="130" show-overflow-tooltip>
        <template>
          <span>{{ packageName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Rate" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.rate" v-if="scope.row.edit === true"></el-input>
          <span style="margin-left: 10px" v-else>{{ scope.row.rate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Description" prop="description" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.description" v-if="scope.row.edit === true"></el-input>
          <span style="margin-left: 10px" v-else>{{ scope.row.description }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Create By" prop="createBy" align="center"></el-table-column>
      <el-table-column label="Create Time" prop="createTime" align="center"></el-table-column>
      <el-table-column label="Action" min-width="160" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.edit === true"
            type="danger"
            size="small"
            @click="submitAdjustRateUpdateClick(scope.row)"
            >Submit</el-button
          >
          <el-button
            v-if="scope.row.edit === true"
            size="small"
            type="info"
            @click="cancelAdjustRateEditClick(scope.row)"
            >Cancel</el-button
          >
          <template v-else>
            <el-button size="small" type="primary" @click="updateAdjustRateProd(scope.row)"
              >Update</el-button
            >
            <el-button size="small" type="danger" @click="delAdjustRateProd(scope.row)">
              Delete
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {
    getAjdustRateList,
    addAdjustRateList,
    updateAdjustRateList,
    deleteAdjustRateList,
  } from 'api/product/detail';
  export default {
    props: {
      packageName: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        createRet: {
          rate: null,
          description: null,
        },
        cacheData: null,
        retList: [],
      };
    },
    mounted() {
      this.getProdList();
    },
    methods: {
      getProdList() {
        getAjdustRateList(this.packageName).then((response) => {
          if (response.code == 200) {
            const result = response.result || [];
            this.retList = result.map((item) => {
              item.edit = false;
              return item;
            });
            this.adjusttotal = response.total;
          } else {
            this.$message.error('包名列表:' + response.message);
          }
        });
      },
      createRetClick() {
        let param = {
          ...this.createRet,
          prod: this.packageName,
        };
        addAdjustRateList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Update',
              type: 'success',
            });
            this.getProdList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      submitAdjustRateUpdateClick(row) {
        let param = {
          id: row.id,
          rate: row.rate,
          description: row.description,
        };
        updateAdjustRateList(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getProdList();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      cancelAdjustRateEditClick(row) {
        this.retList = this.retList.map((item) => {
          if (item.id === this.cacheData.id) {
            return { ...this.cacheData };
          }
          return item;
        });
        row.edit = false;
      },
      updateAdjustRateProd(row) {
        this.cacheData = { ...row };
        if (row.edit === false || row.edit === undefined) {
          row.edit = true;
        }
      },
      delAdjustRateProd(row) {
        deleteAdjustRateList(row).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getProdList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
    },
  };
</script>

<style></style>
