import request from 'utils/request';

const api = {
  SWITCH_PROD_AFF_BLACK: '/api/prod/detail/switchProdAffBlack',
  // adjust rate list
  ADJUST_RATE_LIST: '/api/prod/detail/adjustRateList',
  ADD_ADJUST_RATE_LIST: '/api/prod/detail/addAdjustProdRate',
  UPDATE_ADJUST_RATE_LIST: '/api/prod/detail/updateProdRate',
  DELETE_ADJUST_RATE_LIST: '/api/prod/detail/batchDelProdRate',
  // source blacklist
  PROD_SOURCE_BLACKLIST: '/api/prod/detail/prodSourceBlackList',
  ADD_PROD_SOURCE_BLACKLIST: '/api/prod/detail/addProdSourceBlack',
  UPDATE_PROD_SOURCE_BLACKLIST: '/api/prod/detail/updateProdSourceBlack',
  DELETE_PROD_SOURCE_BLACKLIST: '/api/prod/detail/deleteProdSourceBlack',

  // aff blacklist
  PROD_AFF_BLACKLIST: '/api/prod/detail/prodAffBlackList',
  ADD_PROD_AFF_BLACKLIST: '/api/prod/detail/addProdAffBlack',
  DELETE_PROD_AFF_BLACKLIST: '/api/prod/detail/delProdAffBlack',
};

export function switchProdAffBlack(prodId, switchStatus) {
  return request({
    url: api.SWITCH_PROD_AFF_BLACK,
    method: 'put',
    data: {
      prodId,
      switchStatus,
    },
  });
}

// adjust rate
export function getAjdustRateList(prod) {
  return request({
    url: api.ADJUST_RATE_LIST,
    method: 'get',
    params: {
      prod,
    },
  });
}
export function addAdjustRateList(data) {
  return request({
    url: api.ADD_ADJUST_RATE_LIST,
    method: 'post',
    data,
  });
}
export function updateAdjustRateList(data) {
  return request({
    url: api.UPDATE_ADJUST_RATE_LIST,
    method: 'put',
    data,
  });
}
export function deleteAdjustRateList({ id, prod }) {
  return request({
    url: api.DELETE_ADJUST_RATE_LIST,
    method: 'delete',
    params: {
      prod,
      ids: id,
    },
  });
}

export function getProdSourceBlacklist(query) {
  return request({
    url: api.PROD_SOURCE_BLACKLIST,
    method: 'get',
    params: query,
  });
}
export function addProdSourceBlacklist(data) {
  return request({
    url: api.ADD_PROD_SOURCE_BLACKLIST,
    method: 'post',
    data,
  });
}
export function updateProdSourceBlacklist(data) {
  return request({
    url: api.UPDATE_PROD_SOURCE_BLACKLIST,
    method: 'put',
    data,
  });
}
export function deleteProdSourceBlacklist(prod, ids) {
  return request({
    url: api.DELETE_PROD_SOURCE_BLACKLIST,
    method: 'delete',
    params: {
      prod,
      ids,
    },
  });
}

export function getProdAffBlacklist(query) {
  return request({
    url: api.PROD_AFF_BLACKLIST,
    method: 'get',
    params: query,
  });
}
export function addProdAffBlacklist(data) {
  return request({
    url: api.ADD_PROD_AFF_BLACKLIST,
    method: 'post',
    data,
  });
}
export function deleteProdAffBlacklist(prodId, ids) {
  return request({
    url: api.DELETE_PROD_AFF_BLACKLIST,
    method: 'delete',
    params: {
      prodId,
      ids,
    },
  });
}
