<template>
  <div>
    <el-form :inline="true" :model="blackAffiliate" label-width="80px">
      <el-form-item label="AffiliateId" prop="affiliateId">
        <el-input v-model="blackAffiliate.affiliateId"></el-input>
      </el-form-item>
      <el-form-item label="Description">
        <el-input v-model="blackAffiliate.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addBlackAffiliate">Add</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="blackAffiliateList" border stripe style="width:100%;">
      <el-table-column label="ProductName" align="center" show-overflow-tooltip>
        <template>
          <span>{{ packageName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="AffiliateId"
        prop="affiliateId"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="AffiliateSubId"
        prop="affiliateSubId"
        align="center"
      ></el-table-column>
      <el-table-column label="Description" prop="description" align="center"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime" align="center"></el-table-column>
      <el-table-column label="Action" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="del_action(scope.row)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- del_Action模块框 -->
    <el-dialog
      title="Delete"
      width="40%"
      :visible.sync="delblackAffiliateVisible"
      center
      append-to-body
    >
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delblackAffiliateVisible = false">Cancel</el-button>
        <el-button type="primary" @click="delblackAffiliate">Delete</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getProdAffBlacklist,
    addProdAffBlacklist,
    // updateProdAffBlacklist,
    deleteProdAffBlacklist,
  } from 'api/product/detail';
  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    props: {
      packageName: {
        type: String,
        required: true,
      },
      prodId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        blackAffiliate: {},
        blackAffiliateList: [],
        delblackAffiliateVisible: false,
        total: 0,
        pageSize: 10,
        currentPage: 1,
      };
    },
    mounted() {
      this.getProductAffiliateBlackList();
    },
    methods: {
      getProductAffiliateBlackList() {
        const param = {
          prodId: this.prodId,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getProdAffBlacklist(param).then((response) => {
          if (response.code === 200) {
            this.blackAffiliateList = response.result;
            this.total = response.total;
          } else {
            this.$message.error('渠道黑名单:' + response.message);
          }
        });
      },
      addBlackAffiliate() {
        console.log(this.prodId);
        let param = {
          ...this.blackAffiliate,
          prodId: this.prodId,
        };
        addProdAffBlacklist(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getProductAffiliateBlackList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      del_action(row) {
        this.delblackAffiliateVisible = true;
        this.delId = row.id;
      },
      delblackAffiliate() {
        deleteProdAffBlacklist(this.prodId, this.delId).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getProductAffiliateBlackList();
            this.delblackAffiliateVisible = false;
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getBlackProdList();
      },
    },
  };
</script>

<style></style>
