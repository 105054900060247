<template>
  <div>
    <el-row class="row_line">
      <el-form :model="blackSource" :inline="true" label-width="100px">
        <el-form-item label="Source Id">
          <el-input v-model="blackSource.sourceIds" placeholder="source id"></el-input>
        </el-form-item>
        <!-- <el-form-item label="Source Name">
          <el-input v-model="blackSource.sourceName" placeholder="source name"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getBlackProdList(1)">Search</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-form :model="addBlackSource" :inline="true" label-width="100px">
        <el-form-item label="sourceId">
          <el-select
            v-model="addBlackSource.sourceIds"
            multiple
            :loading="sourceLoading"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option-group v-for="group in sourcesList" :key="group.label" :label="group.label">
              <el-option
                v-for="item in group.children"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="addBlackSource.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addBlackSourceClick">Add</el-button>
          <el-button type="primary" @click="deleteSeletedClick">Delete selected</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
      :data="blackProdList"
      border
      stripe
      style="width:100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column
        label="Prod"
        prop="prod"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="SourceId" prop="sourceId" align="center"></el-table-column>
      <el-table-column
        label="SourceName"
        prop="sourceName"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column label="Description" align="center" min-width="110" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-input v-model="scope.row.description" v-if="scope.row.edit === true"></el-input>
          <span style="margin-left: 10px" v-else>{{ scope.row.description }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Status" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.status" v-if="scope.row.edit === true"></el-input>
          <span style="margin-left: 10px" v-else>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AccountId" prop="accountId" align="center"></el-table-column>
      <el-table-column
        label="AccountName"
        prop="accountName"
        align="center"
        min-width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Date"
        prop="date"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Action" align="center" min-width="160">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.edit === true"
            type="danger"
            size="small"
            @click="submitUpdateClick(scope.row)"
            >Sumbit</el-button
          >
          <el-button
            v-if="scope.row.edit === true"
            size="small"
            type="info"
            @click="cancelEditClick(scope.row)"
            >Cancel</el-button
          >
          <el-button v-else type="primary" size="small" @click="updateClick(scope.row)"
            >Update</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import { mapState, mapActions } from 'vuex';
  import {
    getProdSourceBlacklist,
    addProdSourceBlacklist,
    updateProdSourceBlacklist,
    deleteProdSourceBlacklist,
  } from 'api/product/detail';
  export default {
    components: {
      Pagination,
    },
    props: {
      packageName: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        blackSource: {},
        addBlackSource: {},
        blackProdList: [],
        delArr: [],
        selectedList: [],
        cacheData: null,
        total: 0,
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getSourceIdsList();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      getBlackProdList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.blackSource,
          prod: this.packageName,
          page: this.currentPage,
          pageSize: this.pageSize,
        };

        getProdSourceBlacklist(param).then((response) => {
          if (response.code === 200) {
            this.blackProdList = response.result.map((item) => {
              item.edit = false;
              return item;
            });
            this.total = response.total;
          } else {
            this.$message.error('Error:' + response.message);
          }
        });
      },
      //   searchblackList() {},
      addBlackSourceClick() {
        let param = {
          ...this.addBlackSource,
          status: 'ENABLE',
          prod: this.packageName,
        };

        addProdSourceBlacklist(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getBlackProdList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      submitUpdateClick(row) {
        let param = {
          prodBlackId: row.id,
          prod: row.prod,
        };
        if (row.description !== '' || row.description !== null) {
          param['description'] = row.description;
        }

        if (row.status !== '' || row.status !== null) {
          param['Status'] = row.status;
        }

        updateProdSourceBlacklist(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getBlackProdList();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      deleteSeletedClick() {
        if (this.selectedList.length == 0) {
          this.$message.error('not select record!');
          return;
        }
        const length = this.selectedList.length;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.selectedList[i].sourceId);
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            deleteProdSourceBlacklist(this.packageName, this.delArr.join(',')).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Delete Success',
                  type: 'success',
                });
                this.getBlackProdList();
              } else {
                this.$message.error('Delete Error:' + response.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      updateClick(row) {
        if (row.edit === false || row.edit === undefined) {
          this.cacheData = { ...row };
          row.edit = true;
        }
      },
      cancelEditClick(row) {
        this.blackProdList = this.blackProdList.map((item) => {
          if (item.id === this.cacheData.id) {
            return { ...this.cacheData };
          }
          return item;
        });
        row.edit = false;
      },
      handleSelectionChange(val) {
        this.selectedList = val;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getBlackProdList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row_line {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }
</style>
